export const MunchBakeryApiUrl = 'https://liveapi.munchbakery.com/MunchBakeryAPIService.svc';
// export const MunchBakeryApiUrl = 'http://munchadmin.dnsalias.com:5013/MunchBakeryAPIService.svc';
// export const MunchBakeryApiUrl = 'https://api.munchbakery.com/MunchBakeryAPIService.svc';


// export const API_URL = 'https://development.brstdev.com:5020';
// export const API_URL = 'https://development.brstdev.com:5011';

// export const API_URL = 'http://localhost';
export const API_URL = 'https://munchcake.com';



// export const Timslots = [
//     {"id":1, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM"},
//     {"id":3, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":4, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":5, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":6, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":7, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":8, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":9, "afternoon":"1:00 PM till 05:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":12, "afternoon":"1:00 PM till 06:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":14, "afternoon":"10:00 PM till 2:00 AM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":15, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":16, "afternoon":"01:00 PM till 05:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":17, "afternoon":"1:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":18, "afternoon":"1:00 PM till 05:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":19, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":20, "afternoon":"1:00 PM till 05:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":60, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":22, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":23, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":25, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":27, "afternoon":"1:00 PM till 05:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":56, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":29, "afternoon":"1:00 PM till 05:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":21, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":33, "afternoon":"1:00 PM till 05:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":30, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":35, "afternoon":"01:00 PM till 5:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":34, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":38, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":40, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":46, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":48, "afternoon":"1:00 PM till 05:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":51, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":53, "afternoon":"1:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":56, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":37, "afternoon":"01:00 PM till 6:00 PM" , "night": "10:00 PM till 2:00 AM" },
//     {"id":61, "afternoon":"01:00 PM till 5:00 PM" , "night": "10:00 PM till 2:00 AM" }
// ];

export const Timslots = [
    { "day": "Sunday", "start": "11:00", "end": "22:00" },
    { "day": "Monday", "start": "11:00", "end": "22:00" },
    { "day": "Tuesday", "start": "11:00", "end": "22:00" },
    { "day": "Wednesday", "start": "11:00", "end": "22:00" },
    { "day": "Thursday", "start": "11:00", "end": "22:00" },
    { "day": "Friday", "start": "15:00", "end": "22:00" },
    { "day": "Saturday", "start": "11:00", "end": "22:00" },
];

