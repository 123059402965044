import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from 'react-router-dom';
import { ExecutePaymentGateway, GetPaymentStatusGateway, InitiateSessionGateway, sendPaymentGateway } from '../../actions/payment_gateways';
import axios from "axios";
import { API_URL, MunchBakeryApiUrl } from '../../global';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CryptoJS from "crypto-js";

import { ToastContainer, toast } from "react-toastify";
import { TrackGoogleAnalyticsPageView } from '../../utils/google-analytics/events';
import { TrackGoogleTagManagerPageView } from '../../utils/google-tag-manager/events';


export default function PaymentLoader() {
    const store = useSelector((state) => state);
    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const local_store = useState(user);
    const custGuid = store?.auth?.user?.cust_guid || local_store[0]?.cust_guid;
    const custId = local_store[0]?.cus_id ? local_store[0]?.cus_id : ""
    const custtoken = store?.auth?.user?.cus_to || local_store[0]?.cus_to;
    const RedirectingText = store.Lang.data?.page_loader ? store.Lang.data.page_loader : '';
    const langId = store?.Lang?.data?.lang ? store?.Lang?.data?.lang : '';
    const location = useLocation();
    const navigate = useNavigate();
    const [getCounteryCode, setCounteryCode] = useState(localStorage.getItem("cusCounteryCode") ? JSON.parse(localStorage.getItem("cusCounteryCode")) : "");
    const [getExecutePaymentState, setExecutePaymentState] = useState(localStorage.getItem("ExecutePaymentPayload") ? JSON.parse(localStorage.getItem("ExecutePaymentPayload")) : "");
    const [getCustomerIdentifierState, setCustomerIdentifierState] = useState(localStorage.getItem("random_number") ? localStorage.getItem("random_number") : "");


    const [getPhoneNumber, setPhoneNumber] = useState(localStorage.getItem("cusMob") ? JSON.parse(localStorage.getItem("cusMob")) : "");
    const [getInvoiceId, setInvoiceId] = useState(localStorage?.getItem("invoiceId") ? localStorage?.getItem("invoiceId") : "");
    const [getTransactionMessage, setTransactionFailedMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");




    useEffect(() => {

        const urlSearchString = location?.search;
        const params = new URLSearchParams(urlSearchString);
        const paymentId = params.get('paymentId');
        const Id = params.get('Id');



        if (getInvoiceId && paymentId && Id) {
            if (store.Lang.data?.lang) {
                GetPaymentStatusHandler(getInvoiceId, paymentId, Id)
            }
        } else {
            if (store.Lang.data?.lang) {
                window?.location?.replace("/");
            }
        }

    }, [location])



    const GetPaymentStatusHandler = async (InvoiceId, paymentId, Id) => {

        let form_data = {
            "Key": paymentId?.toString(),
            "KeyType": "PaymentId"
        }

        try {
            const response = await GetPaymentStatusGateway(form_data);
            if (response?.data?.IsSuccess) {
                let transaction_details = response?.data?.Data;

                if (transaction_details?.InvoiceStatus == "Paid" && transaction_details?.InvoiceTransactions[0]?.TransactionStatus == "Succss") {
                    localStorage.setItem("InvoiceValue", JSON.stringify(response?.data?.Data?.InvoiceValue));
                    let order_payload_details = localStorage?.getItem("OrderPayload") ? JSON.parse(localStorage?.getItem("OrderPayload")) : "";
                    if (order_payload_details) {
                        // await createOrder(order_payload_details, transaction_details);
                        await updateOrderStatus(order_payload_details, transaction_details);
                    }
                    return;
                } else if (transaction_details?.InvoiceStatus == "Pending" && transaction_details?.InvoiceTransactions[0]?.TransactionStatus == "Failed") {

                    await sendPaymentHandler(transaction_details);
                    // let error_message = (transaction_details?.InvoiceTransactions[0]?.Error)?.toLowerCase();
                    // let state = { err: error_message, InvoiceStatus: transaction_details?.InvoiceStatus, TransactionStatus: transaction_details?.InvoiceTransactions[0]?.TransactionStatus };
                    // localStorage.setItem("transaction_status", JSON.stringify(state));
                    // return navigate("/thankyouPage", { state: state });
                    // setTransactionFailedMessage(true);
                    // setErrorMessage(`${error_message}`);
                    // let order_payload_details = localStorage?.getItem("OrderPayload") ? JSON.parse(localStorage?.getItem("OrderPayload")) : "";

                }
            } else {
                return console.log(response?.data);
            }

        } catch (error) {
            console.log(error, "error");
            alert(error?.response?.data?.error);
        }
    }


    const sendPaymentHandler = async (transaction_details) => {


        localStorage.removeItem("session_details");
        const random_generate = Math.random().toString(36).slice(2).toUpperCase();

        // localStorage?.setItem("ShoppingDetails", JSON.stringify(ShoppingDetails));


        let code = "";
        let mob_number = "";
        if (localStorage?.getItem("cusCounteryCode") && localStorage?.getItem("cusMob")) {
            code = JSON.parse(localStorage?.getItem("cusCounteryCode"))?.code;
            mob_number = JSON.parse(localStorage?.getItem("cusMob"))?.phone;
        }

        // let cart_details = "";
        // if (localStorage?.getItem("cart_details")) {
        //     cart_details = JSON.parse(localStorage?.getItem("cart_details"));
        // }


        let form_data = {
            "NotificationOption": "LNK",
            "CustomerName": transaction_details?.CustomerName ? transaction_details?.CustomerName : "",
            "DisplayCurrencyIso": "SAR",
            "MobileCountryCode": code?.toString(),
            "CustomerMobile": mob_number?.toString(),
            "CustomerEmail": transaction_details?.CustomerEmail ? transaction_details?.CustomerEmail : "",
            "InvoiceValue": transaction_details?.InvoiceValue ? transaction_details?.InvoiceValue : "",
            "CallBackUrl": 'http://caketest2.munchbakery.com/UpdateMyFatoorah/Index',
            "ErrorUrl": 'http://caketest2.munchbakery.com/UpdateMyFatoorah/Index',
            "Language": langId,
            "CustomerReference": transaction_details?.UserDefinedField ? `F${transaction_details?.UserDefinedField}` : random_generate?.toString(),
            "CustomerCivilId": random_generate?.toString(),
            "UserDefinedField": transaction_details?.UserDefinedField ? transaction_details?.UserDefinedField : "",
            // "Suppliers": [
            //     {
            //         "SupplierCode": cart_details?.val?.PaymentGatewayID ? parseInt(cart_details?.val?.PaymentGatewayID) : "",
            //         "ProposedShare": null,
            //         "InvoiceShare": transaction_details?.InvoiceValue ? transaction_details?.InvoiceValue : ""
            //     }
            // ],
            // "ExpireDate": new Date(new Date().setHours(new Date().getHours() + 4)),
        }

        try {
            const response = await sendPaymentGateway(form_data);
            if (response?.data?.IsSuccess) {
                let inVoiceId = response?.data?.Data?.InvoiceId;
                let invoiceURL = response?.data?.Data?.InvoiceURL;

                if (transaction_details?.UserDefinedField) {
                    if (inVoiceId && invoiceURL && form_data) {
                        await updateInvoiceInDBHandler(transaction_details, inVoiceId, invoiceURL);
                    }
                }
                return;
            } else {
                return alert(response?.data?.ValidationErrors[0]?.Error);
            }

        } catch (error) {
            console.log(error, "error");
            alert(error?.Message);
        }

    }

    const updateInvoiceInDBHandler = async (transaction_details = "", inVoiceId = "", invoiceURL = "") => {

        let form_data = {
            "orderNumber": transaction_details?.UserDefinedField ? transaction_details?.UserDefinedField : "",
            "invoiceURL": invoiceURL ? invoiceURL : "",
            "invoiceId": inVoiceId ? inVoiceId : "",
            "paymentProvider": 3

        };

        try {
            let response = await axios.post(`${MunchBakeryApiUrl}/UpdateInvoiceInforDB/`, form_data);
            if (response?.data?.UpdateInvoiceInforDBResult?.Status && response?.data?.UpdateInvoiceInforDBResult?.Status == "SUCCESS") {
                await GetInfoTextMunchCake(form_data?.orderNumber, form_data?.invoiceURL, form_data?.invoiceId, transaction_details)
            } else {
                console.log("UpdateInvoiceInforDB - err::", response?.data?.UpdateInvoiceInforDBResult);
                alert(response?.data?.UpdateInvoiceInforDBResult?.ErrorResponse?.ErrorMessage);
                window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
                return window?.location?.replace("/");
            }
        } catch (error) {
            console.log("err:", error);
        }

    }


    const createOrder = async (order_payload_details, transaction_details) => {

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${order_payload_details?.token}`,
                    "Content-Type": "application/json"
                }
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/CreateOrderMunchBakery/`, order_payload_details?.orders, header);
            if (response?.data?.Status == "ERROR") {
                console.log("err", response?.data);
                toast.error(response?.data?.ErrorResponse?.ErrorMessage, { position: toast.POSITION.TOP_LEFT });
                alert(response?.data?.ErrorResponse?.ErrorMessage);
                localStorage?.setItem("transaction_success", response?.data?.ErrorResponse?.ErrorMessage);
                window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
                window.location.replace("/");
                return;
            } else {
                let orderNumber = response?.data?.SuccessResponse;
                let path = '/thankyouPage';
                let state = order_payload_details?.state;
                state["order_id"] = orderNumber;
                return navigate(path, { state: state, replace: true });
                // await updateOrderStatus(orderNumber, order_payload_details, transaction_details);
                // await paymentLinkHandler(path, state, order_payload_details);
            }

        } catch (error) {
            console.log("err", error);
        }



    }

    const updateOrderStatus = async (order_payload_details, transaction_details) => {

        try {

            let form_data = {
                OrderGuid: order_payload_details?.state?.order_id,
                PaymentMerchantRefId: transaction_details?.CustomerMobile,
                CreditCardTransNumber: transaction_details?.CustomerReference
            }

            const header = {
                headers: {
                    "Authorization": `basic ${order_payload_details?.token}`,
                    "Content-Type": "application/json"
                }
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/UpdateOrderPaymentCreditCard/`, form_data, header);
            if (response?.data?.Status == "ERROR") {
                console.log("err", response?.data);
                alert(response?.data?.ErrorResponse?.ErrorMessage);
                localStorage?.setItem("transaction_success", response?.data?.ErrorResponse?.ErrorMessage);
                window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
                window.location.replace("/");
                return;
            } else {
                let path = '/thankyouPage';
                let state = order_payload_details?.state;

                TrackGoogleAnalyticsPageView("pageview", (window.location.origin + "/thankyouPage" + ""), "Purchase");
                TrackGoogleTagManagerPageView("Purchase", (window.location.origin + "/thankyouPage" + ""));
                return navigate(path, { state: state, replace: true });

            }

        } catch (error) {
            console.log("err", error);
        }



    }

    // const paymentLinkHandler = async (path, state, order_payload_details) => {

    //     let language = order_payload_details?.orders?.LanguageId;
    //     let custtoken = order_payload_details?.token;

    //     try {

    //         const header = {
    //             headers: {
    //                 "Authorization": `basic ${custtoken}`,
    //                 "Content-Type": "application/json"
    //             }
    //         }

    //         const data = {
    //             OrderNumber: state?.order_id,
    //             langId: language
    //         }

    //         const response = await axios.post(`${MunchBakeryApiUrl}/CreatePaymentForPayTabsWeb/`, data, header);
    //         if (response?.data?.CreatePaymentForPayTabsWebResult?.Status == "SUCCESS") {
    //             await GetInfoTextMunchCake(path, state, response?.data?.CreatePaymentForPayTabsWebResult?.SuccessResponse, order_payload_details)
    //         }
    //     } catch (error) {
    //         console.log("err", error);
    //     }

    // }

    const GetInfoTextMunchCake = async (order_number, paymentLink, invoice_id, transaction_details) => {

        // let language = order_payload_details?.orders?.LanguageId;
        // let custtoken = order_payload_details?.token;

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                langId: langId == "en" ? 1 : 0
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/GetInfoTextMunchCake/`, data, header);
            if (response?.data?.GetInfoTextMunchCakeResult?.length > 0) {
                let TextExist = response?.data?.GetInfoTextMunchCakeResult?.filter((v, i) => v?.Name == "TextforSMS");
                let descript = TextExist[0]?.Description;
                let dynamic_replace = descript?.replace("[order Number]", order_number);
                let new_replace_text = dynamic_replace.replace("[payment link]", paymentLink);
                let convertible_text = await convertToPlain(new_replace_text);
                await new Promise((resolve, reject) => setTimeout(() => resolve()), 1000);
                await sendLinkOnSms(convertible_text, transaction_details);
            }
        } catch (error) {
            console.log("err", error);
        }

    }

    const convertToPlain = async (html) => {

        // Create a new div element
        let tempDivElement = document.createElement("div");

        // Set the HTML content with the given value
        tempDivElement.innerHTML = html;

        // Retrieve the text property of the element
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }


    const sendLinkOnSms = async (convertible_text, transaction_details) => {
        let country_code = getCounteryCode?.code;
        let mobile = getPhoneNumber?.phone;
        let mob = mobile.replace(/^0+/, "");
        let number = country_code?.replace(/\s+/g, "").replace(/\+/g, "");
        let new_number = `${number}${mob}`;

        try {

            const header = {
                headers: {
                    "Content-Type": "application/json"
                }
            }

            const data = {
                recipient: new_number,
                body: convertible_text,
                type: "text"
            };


            const response = await axios.post(`${API_URL}/sms-otp`, data, header);
            if (response?.data?.data?.success) {
                // navigate(path, { state: state });
                let error_message = (transaction_details?.InvoiceTransactions[0]?.Error)?.toLowerCase();
                let state = { err: error_message, InvoiceStatus: transaction_details?.InvoiceStatus, TransactionStatus: transaction_details?.InvoiceTransactions[0]?.TransactionStatus };
                localStorage.setItem("transaction_status", JSON.stringify(state));
                return navigate("/thankyouPage", { state: state });
                return;
            } else {
            }
        } catch (error) {
            console.log("err", error);
        }
    }

    const ErrorHandler = () => {
        return window.location.replace("/");
    }


    const ShopGetShoppingCart = async (order_payload_details) => {
        if (order_payload_details) {
            const form_data = {
                CustomerGuid: order_payload_details?.orders?.CustomerGuid,
                CustomerId: order_payload_details?.orders?.CustomerId,
                OrderDate: order_payload_details?.state?.getCartDetail?.OrderDate ? order_payload_details?.state?.getCartDetail?.OrderDate : "",
                StoreId: order_payload_details?.orders?.StoreId ? (order_payload_details?.orders?.StoreId)?.toString() : "",
                langId: order_payload_details?.orders?.LanguageId,
                CityId: "-1",
                DistrictId: order_payload_details?.orders?.DistrictId ? order_payload_details?.orders?.DistrictId : "",
                ZoneId: "-1",
                OrderType: order_payload_details?.orders?.OrderTypeId ? (order_payload_details?.orders?.OrderTypeId)?.toString() : ""
            }

            try {
                const header = {
                    headers: {
                        "Authorization": `basic ${order_payload_details?.token}`,
                        "Content-Type": "application/json"
                    }
                }
                const response = await axios.post(`${MunchBakeryApiUrl}/GetShoppingcartsummary/`, form_data, header);
                if (response?.data?.GetShoppingCartSummaryResult?.LstECommerceShoppingCartItem) {
                    let cartItems = response?.data?.GetShoppingCartSummaryResult?.LstECommerceShoppingCartItem;
                    cartItems?.map(async (v, i) => {
                        await AddUpdateShoppingCart(v, form_data);
                    })
                }
            } catch (error) {
                console.log("err", error);
            }
        }


    }

    const AddUpdateShoppingCart = async (result, form_data, order_payload_details) => {
        let order_date = moment(result?.UpdatedOn, 'DD/MM/YYYY');
        let newOrderDate = moment(order_date).format("MM/DD/YYYY");

        const data = {
            CustomerId: form_data?.CustomerId,
            CustomerGuid: form_data?.CustomerGuid,
            CityId: form_data?.CityId,
            DistrictId: form_data?.DistrictId,
            MarkAsDeleted: 1,
            OrderDate: newOrderDate,
            Price: result?.Price,
            ProductId: result?.ProductId,
            Quantity: result?.Quantity,
            ShoppingCartId: result?.ShoppingCartId,
            StoreId: result?.StoreId,
            ZoneId: "15"
        };

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${order_payload_details?.token}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/AddUpdateShoppingCartItemMunchBakery/`, data, header);
            if (response?.data) {
                await ShopGetShoppingCart(order_payload_details);
            }
        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }

    const initiateSessionHandler = async () => {
        localStorage?.setItem("start_session", "true");
        let Others = localStorage?.getItem("Others") ? JSON.parse(localStorage?.getItem("Others")) : "";
        let states = {
            others: Others
        }
        navigate("/customizeThird", { state: states, replace: false });
        return;
        const random_generate = Math.random().toString(36).slice(2)?.toUpperCase();

        let form_data = {
            "CustomerIdentifier": (random_generate)?.toString(),
            "SaveToken": false
        }

        try {
            const response = await InitiateSessionGateway(form_data);
            if (response?.data?.IsSuccess) {
                let session_details = response?.data?.Data;
                let CustomerIdentifier = form_data?.CustomerIdentifier;
                localStorage.setItem("session_details", JSON.stringify(session_details));
                localStorage.setItem("random_number", CustomerIdentifier);
                await ExecutePaymentGatewayHandler(CustomerIdentifier || '', session_details || '');
                return
            } else {
                return;
            }

        } catch (error) {
            console.log(error, "error");
        }

    }


    const ExecutePaymentGatewayHandler = async (CustomerIdentifier, sessionDetails) => {
        if (getExecutePaymentState) {

            getExecutePaymentState["CustomerReference"] = CustomerIdentifier;
            getExecutePaymentState["CustomerCivilId"] = CustomerIdentifier;
            getExecutePaymentState["SessionId"] = sessionDetails?.SessionId;

            try {
                const response = await ExecutePaymentGateway(getExecutePaymentState);
                if (response?.data?.IsSuccess) {
                    localStorage.setItem("invoiceId", response?.data?.Data?.InvoiceId);
                    window.open(response?.data?.Data?.PaymentURL, "_self");
                    return;
                } else {
                    let Bypass3DS_err = response?.data?.ValidationErrors;
                    let Bypass3DS_array = Bypass3DS_err?.map((v, i) => v?.Error);
                    alert(Bypass3DS_array);
                    return;
                }

            } catch (error) {
                console.log(error, "error");
            }
        }

    }


    return (
        <Box component='div' className='page_default start_next_page'>
            <ToastContainer />
            {/* {
                getTransactionMessage ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', width: '-webkit-fill-available', minWidth: '100%' }}>
                            <Box component='div' className='banner h1' style={{ textAlign: 'center' }}>
                                <img alt='Transaction Failed' src="images/transaction_failed.svg" />
                                <h1 className=''>Transaction Failed.</h1>
                                <p style={{ color: 'red', textAlign: "center", textTransform: "capitalize" }}>{errorMessage}</p>
                            </Box>
                        </Box>

                        <Box component='div' className='step_button_box' style={{ position: "unset" }}>
                            <Box component='div' className='btn'>
                                <Button className="upload_btn" onClick={() => initiateSessionHandler()} >
                                    Try again
                                </Button>
                            </Box>
                        </Box>

                        <Box component='div' className='step_button_box' style={{ position: "unset" }}>
                            <Box component='div' className='btn'>
                                <Button className="upload_btn" onClick={() => ErrorHandler()} >
                                    Go Back
                                </Button>
                            </Box>
                        </Box>
                    </>

                    :
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', width: '-webkit-fill-available', minWidth: '100%' }}>
                            <CircularProgress />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh', width: '-webkit-fill-available', minWidth: '100%' }}>
                            {RedirectingText?.redirecting}
                        </Box>
                    </>

            } */}

            <>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', width: '-webkit-fill-available', minWidth: '100%' }}>
                    <CircularProgress />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh', width: '-webkit-fill-available', minWidth: '100%' }}>
                    {RedirectingText?.redirecting}
                </Box>
            </>
        </Box>
    )
}
